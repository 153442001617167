<template>
    <b-modal 
        v-model="exibe" 
        body-class="app-modal-custom-body" 
        header-class="app-modal-custom-header" 
        footer-class="app-modal-custom-footer" 
        size="md"
    >
        <template v-slot:default="{ cancel, ok }">

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <b-form-file
                            v-model="arquivo"
                            :state="Boolean(arquivo)"
                            placeholder="Escolha ou Jogue um arquivo..."
                            drop-placeholder="Jogar arquivo..."
                        ></b-form-file>
                    </div>
                </div>

                <div class="row py-2">
                    <div class="col-12">
                        <botao-modal-ok
                            class="float-right ml-2"
                            :disabled="!Boolean(arquivo)"
                            @click="salvar(ok)"
                        >
                            Ok
                        </botao-modal-ok>
                        <botao-modal-cancel 
                            class="float-right ml-2"
                            @click="cancel"
                        >
                            Cancelar
                        </botao-modal-cancel>
                    </div>
                </div>

            </div>

        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>

    </b-modal>
</template>

<script>
import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';

export default {
    name: 'ModalUploadArquivo',

    components: {
        BotaoModalOk,
        BotaoModalCancel,
    },

    props: {
        
    },

    data: function() {
        return {
            exibe: false,
            arquivo: null,
        }
    },

    methods: {
        show() {
            this.arquivo = null
            this.exibe = true
        },
        hide() {
            this.exibe = false
        },
        salvar(ok) {
            this.$emit('upload', this.arquivo)
            ok()
        }
    }
}
</script>

<style>

</style>