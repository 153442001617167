<template>
    <div class="disco-virtual-card-arquivo text-center p-4">
        <div class="disco-particao-imagem mb-2">
            <img id="dv-imagem" :src="diretorioImagem" alt="disco-virtual-imagem" />
        </div>
        <p class="disco-nome-arquivo">{{ nomeArquivoDiminuido }}</p>
        <div class="disco-particao-botoes d-flex justify-content-between">
            <botao-padrao-cinza v-if="!isLogos" @click="deletarArquivo">
                <i class="fas fa-trash-alt text-dark"></i>
            </botao-padrao-cinza>
            <botao-padrao-cinza @click="downloadArquivo">
                <i class="fas fa-download text-dark"></i>
            </botao-padrao-cinza>
            <botao-padrao-cinza v-if="isLogos" @click="editLogo">
                <i class="fas fa-pen text-dark"></i>
            </botao-padrao-cinza>
            <botao-padrao texto="Copiar URL" style="font-size: 9px;" @click="copiarUrl" />
        </div>
        <ModalUploadArquivo ref="modal_upload" @upload="editarLogoExistente" />
    </div>
</template>

<script>
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
    import BotaoPadraoCinza from '@/components/botoes/BotaoPadraoCinza.vue'
    import ModalUploadArquivo from '@/components/modals/ModalUploadArquivo.vue'

    export default {
        name: 'DiscoVirtualCard',
        components: {
            BotaoPadrao,
            BotaoPadraoCinza,
            ModalUploadArquivo
        },

        props: {
            urlArquivo:  { String, default: '' },
            isDiscoRaiz: { Boolean, default: false },
            isLogos:     { Boolean, default: false }
        },

        computed: {
            arquivoNome() {
                return this.urlArquivo?.split("\\").pop()
            },

            diretorioImagem() {
                if (this.isDiscoRaiz) {
                    return "/DiscoVirtual/" + this.arquivoNome
                }
                return this.urlArquivo.split("wwwroot")[1]
            },

            nomeArquivoDiminuido() {
                const NOME_EXIBICAO_DIMINUIDO = this.arquivoNome.length - 25;

                return this.arquivoNome.length < 30 ? this.arquivoNome
                    : `...${this.arquivoNome.substring(NOME_EXIBICAO_DIMINUIDO)}` 
            }
        },

        methods: {
            deletarArquivo() {
                this.$emit('deletar-arquivo', this.diretorioImagem)
            },
            copiarUrl() {
                let input = document.createElement('input');
                input.value = window.location.origin + '/' + this.diretorioImagem
                document.body.appendChild(input)
                input.select()
                document.execCommand('copy');
                document.body.removeChild(input);
            },
            downloadArquivo() {
                const element = document.createElement('a')
                element.href = this.diretorioImagem

              
                element.setAttribute('download', this.arquivoNome)
                document.body.appendChild(element)
                element.click()
                document.body.removeChild(element);
            },
            editLogo() {
                this.$refs.modal_upload.show()
            },
            editarLogoExistente(file) {
                this.$emit('editar-arquivo', file, this.nomeArquivoDiminuido)
            }
        }

    }
</script>

<style scoped>
    .disco-particao-imagem {
        background-color: var(--cinza-1);
        width: 100%;
        height: 220px;
        display: grid;
    }

        .disco-particao-imagem img {
            margin: auto;
            max-width: 100%;
            max-height: 220px;
        }

    .disco-nome-arquivo {
        font-size: .8rem;
        font-weight: 600;
    }

</style>