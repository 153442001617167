<template>
    <div>

        <b-form-group class="input-file m-0">

            <b-form-file 
                class="text-center"
                ref="file-input"
                :placeholder="placeholder"
                :accept="tiposArquivoAceitos"
                @change="$emit('change', $event.target.files)" >
            </b-form-file>

        </b-form-group>

    </div>
</template>

<script>
    export default {
        name: 'InputFile',
        props: {
            placeholder: { String, default: 'SELECIONE UM ARQUIVO' },
            tiposArquivoAceitos: { String, default: '.jpg, .jpeg, .png'}
        },
        methods: {
            limparArquivo() {
                this.$refs['file-input'].reset();
            }
        }

    }
</script>

<style>
    .input-file .custom-file-label {
        background-color: var(--cor-primaria-cliente);
        color: white;
        display: inline-block;
        border: 0;
        border-radius: 0;
    }

        .input-file .custom-file-label:hover {
            opacity: .8;
            cursor: pointer;
            outline: none;
        }

    .input-file .custom-file-label::after {
        display: none;
    }
</style>